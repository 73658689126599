import React, {useRef, useState} from "react";
import PropTypes from "prop-types";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BookingViewItem from "../BookingViewItem";
import moment from 'moment';
import ScreenHeader from '../../Layout/ScreenHeader';
import VesselView from '../../Vessels/VesselView';

const BookingView = ({bookingData,vesselData}) => {
    const page = useRef(1);
    const pageTotal = useRef(1);
    const pageLength = 7;
    let currentData = [];

    let now = moment().subtract(1, 'minutes');
    //let now = moment('08:00','HH:mm');
    for (let z = 0; z < bookingData.length; z++) {
        const bookingListItem = bookingData[z];
        let bookingTime = moment(bookingListItem.Time,'HH:mm');
        let BookingPassed = bookingTime.isSameOrAfter(now);
        if(BookingPassed){
            currentData.push(bookingListItem);
        }
    }

    let totalItems = currentData.length;
    let returnBookingData = [];
    for (let i = 0; i < currentData.length; i += pageLength) {
        returnBookingData.push(currentData.slice(i, i + pageLength));
    }

    let tempVar = totalItems / pageLength;
    pageTotal.current = Math.ceil(tempVar);

    page.current = (page.current < pageTotal.current) ? page.current + 1 : 1;
    //setBookingPage(page.current);


    let returnData;
    if(totalItems > pageLength) {
        let currentPageKey = page.current- 1;
        returnData = returnBookingData[currentPageKey];
    } else {
        returnData = (totalItems > 0) ? returnBookingData[0] : [];
    }

    const vessleOnly = returnData.length > 0 ? false : true;

    return (
        <>
            {!vessleOnly &&
                <div className="tour-table">
                    <ScreenHeader title="Tour Operators Departures" />
                    <Container fluid>
                        <Row className="grid">
                            <Col className="col-1">
                                <h2>Time</h2>
                            </Col>
                            <Col className="col-2">
                                <h2>Tour Operator</h2>
                            </Col>
                            <Col className="col-3">
                                <h2>Tour Name</h2>
                            </Col>
                        </Row>
                        {returnData && returnData.length > 0 && returnData.map((bookingViewItem) => <BookingViewItem bookingItem={bookingViewItem}/>)}
                    </Container>
                </div>
            }
            <VesselView vesselData={vesselData} vessleOnly={vessleOnly} />
        </>
    );
};

BookingView.propTypes = {
    loading: PropTypes.bool
};

BookingView.defaultProps = {
    loading: false
};

export default BookingView;