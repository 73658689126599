import React from "react";
import PropTypes from "prop-types";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';

const VesselViewItem = ({vesselItem}) => {
    console.log(vesselItem.Departure)
    
    let formattedVesselTime = moment(vesselItem.Departure,'HH:mm').format('HH:mm');

    let offset = moment.duration("00:30:00");
    let boardingVessel = moment(vesselItem.Departure,'HH:mm').subtract(offset);
    let boardingVesselTime = moment(boardingVessel, 'HH:mm').format('HH:mm');

    return (
        <div>
            <p className="departure-time">
                {formattedVesselTime}
            </p>
            <p className="boarding-time">
                {boardingVesselTime}
            </p>
            <p className="vessel-name">
                {vesselItem.Vessel}
            </p>
        </div>
    );
};

VesselViewItem.propTypes = {
    loading: PropTypes.bool
};

VesselViewItem.defaultProps = {
    loading: false
};

export default VesselViewItem;