import React from "react";
import PropTypes from "prop-types";
import VesselViewItem from "../VesselViewItem";
import Container from 'react-bootstrap/Container';
import ShipIcon from './ship.svg';
import Clock from "../../Layout/Clock";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';

const VesselView = ({vesselData,vessleOnly}) => {

    //let now = moment('17:46','HH:mm');
    let now = moment();
    let currentData = [];
    for (let z = 0; z < vesselData.length; z++) {
        let VesselHere = true;
        let arrivalTime = vesselData[z].Arrival;
        let departureTime = vesselData[z].Departure;

        if(arrivalTime !== ''){
            let vesselArrivalTime = moment(arrivalTime,'HH:mm');
            VesselHere = vesselArrivalTime.isSameOrBefore(now);
        }
    
        if(VesselHere && departureTime !== ''){
            let vesselDepartureTime = moment(departureTime,'HH:mm');
            VesselHere = vesselDepartureTime.isSameOrAfter(now);
        }
        
        if(VesselHere) {
            currentData.push(vesselData[z]);
        }
    }
    
    const isVessle = vessleOnly;

    if(isVessle) {
        return currentData.length > 0 ?
            <VesselTable vesselData={currentData} />
            :
            <NoVessels />        
    } else {
        return <VesselFooter vesselData={currentData} />
    }
};

VesselView.propTypes = {
    loading: PropTypes.bool
};

VesselView.defaultProps = {
    loading: false
};

function VesselTable({vesselData}) {
    return (
        <div>
            <div className="vessel-table vessel-grid">
                <Container fluid className="pb-0">
                    <div className="pb-0">
                        <div className="vessel-title d-flex align-items-center">
                            <div className="ship-icon">
                                <img src={ShipIcon}/>
                            </div>
                            <h1>Vessel Departures</h1>
                            <div className="time">
                                <Clock/>
                            </div>
                        </div>
                    </div>
                    <div className="vessel-list">
                        <Row className="grid">
                            <Col className="column-1">
                                <h2>Departure</h2>
                            </Col>
                            <Col className="column-2">
                                <h2>Final Boarding</h2>
                            </Col>
                            <Col className="column-3">
                                <h2>Vessel Name</h2>
                            </Col>
                        </Row>
                        {vesselData && vesselData.length > 0 && vesselData.map(
                            (vesselViewItem, index) =>
                                index < 2 && (
                                    <VesselViewItem vesselItem={vesselViewItem}/>
                                )
                        )
                        }
                    </div>
                </Container>
            </div>
            <VesselFooter vesselData={vesselData} />
        </div>

)
    ;
}

function VesselFooter({vesselData}) {
    return (
        <div className="vessel-table">
            <Container fluid className="pb-0">
                <div className="d-flex align-items-center justify-content-between pb-0">
                    <div className="vessel-title">
                        <div className="ship-icon">
                            <img src={ShipIcon} />
                        </div>
                        <h1>Vessel Departures</h1>
                    </div>
                    <div className="vessel-list">
                        {vesselData && vesselData.length>0 && vesselData.map(
                            (vesselViewItem, index)=>
                                index < 2 && (
                                <VesselViewItem vesselItem={vesselViewItem}/>
                            )
                        )
                        }
                    </div>
                </div>
            </Container>
        </div>
    );
}

function NoVessels() {
    return (
        <div className="clock-only">
            <div className="clock">
                <Clock />
            </div>
        </div>
    )
}

export default VesselView;