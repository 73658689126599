import React from "react";
import PropTypes from "prop-types";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';

const BookingViewItem = ({bookingItem}) => {
    let bookingTemplate;
    //let now = moment('08:00','HH:mm');
    let now = moment().subtract(1, 'minutes');
    let bookingTime = moment(bookingItem.Time,'HH:mm');
    let BookingPassed = bookingTime.isSameOrAfter(now);
    let formattedBookingTime = moment(bookingItem.Time,'HH:mm').format('HH:mm')
    if (BookingPassed) {
        bookingTemplate = <Row className="grid">
            <Col className="col-1">
                <p>{formattedBookingTime}</p>
            </Col>
            <Col className="col-2">
                <p>{bookingItem.Operator}</p>
            </Col>
            <Col className="col-3">
                <p>{bookingItem.TourName}</p>
            </Col>
        </Row>;
    } else {
        bookingTemplate = '';
    }
    return (
        <>
        {bookingTemplate}
        </>
    );
};

BookingViewItem.propTypes = {
    loading: PropTypes.bool
};

BookingViewItem.defaultProps = {
    loading: false
};

export default BookingViewItem;