import './App.scss';
import {useState, useEffect, useRef} from "react";
import BookingView from "./components/Bookings/BookingView";
import moment from 'moment';
import Clock from './components/Layout/Clock';

function App() {
    const [bookingData,setBookingData]=useState([]);
    const [vesselData,setVesselData]=useState([]);
    const getData=()=>{
        const currentDate = moment().format('YYYY-MM-DD');
        //const currentDate = moment('2023-03-11').format('YYYY-MM-DD');
        fetch(process.env.REACT_APP_BOOKING_URL
        //fetch('https://firebrand-cdn.s3.ap-southeast-2.amazonaws.com/port-otago/tour-operators/port-otago-tours-test.json'
            ,{
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function(response){
                return response.json();
            })
            .then(function(myJson) {
                Object.entries(myJson).forEach((entry) => {
                    const [key, value] = entry;
                    if(key === 'tours'){
                        for (let i = 0; i < value.length; i++) {
                            const bookingItem = value[i];
                            if(bookingItem.date === currentDate) {
                                setBookingData(value[i].bookings);
                            }
                        }
                    }
                    if(key === 'vesselOccurrences'){
                        for (let j = 0; j < value.length; j++) {
                            const vesselItem = value[j];
                            if(vesselItem.date === currentDate) {
                                setVesselData(value[j].vessels);
                            }
                        }
                    }
                });
            });
    }
    useEffect(()=>{
        const refreshRate = (process.env.REACT_APP_REFRESHRATE !== undefined) ? process.env.REACT_APP_REFRESHRATE : 30000;
        const interval = setInterval(
            (function callApi() {
                getData();
                return callApi;
            })(),
            refreshRate
        );
        return () => clearInterval(interval);


    },[])



    const hasData = bookingData.length > 0 ? true : false;

    return (
    <div className="TourOperatorsScreen">
        {hasData ?
            <BookingView bookingData={bookingData} vesselData={vesselData}/>
            :
            <div className="clock-only">
                <div className="clock">
                    <Clock />
                </div>
            </div>
        }
    </div>
  );
}

export default App;
