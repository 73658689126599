import React from "react";
import Clock from "../Clock";
import PropTypes from "prop-types";
import Container from 'react-bootstrap/Container';
import CarIcon from './car.svg';

const ScreenHeader = ({title}) => {
    return (
        <>
            <Container fluid className="d-flex align-items-center justify-content-between pb-0">
                <div className="title d-flex align-items-center">
                    <div className="car-icon">
                        <img src={CarIcon} />
                    </div>
                    <h1>{title}</h1>
                </div>
                <div className="time">
                    <Clock />
                </div>
            </Container>
        </>
    );
};

ScreenHeader.propTypes = {
    loading: PropTypes.bool
};

ScreenHeader.defaultProps = {
    loading: false
};

export default ScreenHeader;